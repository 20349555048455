@import './constants'

@keyframes blinking
  from
    opacity: 0.5
  to
    opacity: 1

@keyframes resizing
  from
    transform: translate(-50%, -50%) scale(1.15)
  to
    transform: translate(-50%, -50%) scale(0.8)

$btn-spacing: 1.25rem

.PlaceWrapper
  position: absolute
  &.show-tooltip
    z-index: 10

.Place
  width: 1.125rem
  height: 1.125rem
  border: 2px solid white
  border-radius: 2rem
  background: $primary
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%) scale(var(--seat-scale))
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5)
  text-decoration: none
  overflow: hidden
  cursor: pointer
  transition: width .15s, height .15s, background .15s, border-width .15s

  span
    display: block

  &.isAvailable
    background: $valid

  &.isDisabled
    cursor: grab
    background: $gray-3

  &.isInactive
    background: $gray-3

  &.isUnmarked
    background: $primary-lighter

  &.isBlinking
    cursor: move
    background: red
    opacity: 0.5
    animation: blinking ease-in-out 500ms alternate infinite

  &.isSelected
    width: 2rem
    height: 2rem
    border-width: 7px

  &.isCurrentlyBooked
    background: $primary

  &.isOccupied
    background: $danger

  &.isHighlighted
    border: 3px solid white
    width: 1.5rem
    height: 1.5rem

  &.isFocused
    border: 3px solid white
    width: 1.5rem
    height: 1.5rem
    animation: resizing linear 500ms alternate infinite

  &.isHovered
    width: 1.5rem
    height: 1.5rem
    border-width: 3px
    animation: none

    & ~ .place-tooltip
      z-index: 40

.place-tooltip
  color: $white !important
  background-color: $gray-4 !important
  opacity: 1 !important
  font-weight: 700
  z-index: 20
