@import '../../components/constants'

.SettingsDepartments

  .Filters
    width: 42rem

  .Card
    min-width: 42rem
    margin-bottom: 3rem

  .department
    margin: 1rem

    .main
      display: flex
      align-items: center
      margin-bottom: 2rem

      .data
        flex-grow: 1
        display: flex
        flex-direction: column
        align-items: stretch
        justify-content: flex-start

        .primary
          font-weight: 700
          font-size: $font-size-normal

        .secondary
          font-weight: 400
          font-size: $font-size-normal
          color: $gray-4

    .statistics
      margin-bottom: 2rem

      .applied
        margin-bottom: 0.2rem
        color: $gray-4
        font-weight: 700
        font-size: $font-size-small
