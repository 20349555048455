@import constants
@import "../utils"

.ManageFloorPlan
  .TooltipPlaceContent
    padding: toRem(6) 0
    max-width: toRem(240)

    .MetaPlaceContent
      display: flex
      flex-direction: column
      gap: toRem(12)

      .PlaceTitle
        font-size: toRem(16)

      .PlaceDepartments
        display: flex
        gap: toRem(10)

        > span
          color: $black
          background: $gray-2
          font-weight: 400
          font-size: toRem(12)
          line-height: toRem(18)
          padding: toRem(2) toRem(4)

      .PlaceAmenities
        > span
          font-size: toRem(12)
          line-height: toRem(16)
          color: $gray-3
          font-weight: 400

          &:not(:last-child)
            &:after
              content: ", "

    .PlaceReservations
      margin-top: toRem(8)

      > .PlaceReservationsRow
        display: flex
        justify-content: space-between
        gap: toRem(30)
        padding: toRem(3) 0
        font-size: toRem(12)
        line-height: toRem(18)
        font-weight: 400

        > div
          &:last-child
            white-space: nowrap


