@import '../../constants'

.SubscriptionCard
  min-width: 20rem

  .subscription-header
    display: flex
    align-items: center
    justify-content: space-between

    .demo
      display: flex
      align-items: center
      color: $primary
      text-decoration: none

      font-size: 14px
      font-weight: 700

      & > svg
        height: 20px
        margin-right: 10px

  .plan-info
    display: flex
    align-items: center
    justify-content: space-between

    .plan-info-title
      font-size: 1rem
      font-weight: 700
      color: $black
      line-height: 1.5rem
      margin-bottom: .5rem

    .plan-info-desc
      font-weight: 700
      color: $gray-3
      font-size: .75rem
      line-height: 1rem
      text-transform: uppercase

    .plan-icon
      width: 3rem
      height: 3rem

      path
        fill: $gray-2

      &.active path
        fill: $primary

  .subscription-group-description, .subscription-details
    margin-top: 1.5rem
    .licenses, .active
      font-size: 1.125rem
      line-height: 1.5rem

      .Button.link
        font-weight: 400
        line-height: 1.5rem

    .active
      margin-bottom: .5rem

    .small-details
      font-size: .625rem
      line-height: 0.75rem

    .small-details ~ .small-details
      margin-top: .5rem
